import React from "react"
import { Link } from 'gatsby'
import { Container } from "react-bootstrap"

const Footer = () => {
  return (
    <Container className='footer'>
      <div className='footer-top layout-foo'>
        <Link className='footer-title' to="/">Shoprises</Link>
        <div className='footer-links'>
          <div className='title'>Solution</div>
          <ul>
            <li><Link to='/oneStop'>ALL-IN-ONE Solution</Link></li>
            <li><Link to='/cases'>Website Themes</Link></li>
            <li><Link to='/saas'>Benefits of Shoprises</Link></li>
          </ul>
        </div>
        <div className='footer-links'>
          <div className='title'>Terms & Policy</div>
          <ul>
            <li><Link to='/termsofUse'>Terms of Use</Link></li>
            <li><Link to='/termsofService'>Terms of Service</Link></li>
            <li><Link to='/privacy'>Privacy Policy</Link></li>
          </ul>
        </div>
        <div className='footer-connect'>
          <div>Contact us</div>
          {/* <div><Link to='/contact'>Consultation</Link></div> */}
          <div>Email: support@shoprises.com</div>
        </div>
      </div>
      <div className='footer-bottom layout-foo'>© Copyright 2020. Shoprises Inc.</div>
    </Container>
  )
}
  

export default Footer
