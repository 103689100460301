import React from "react"
import { Container } from "react-bootstrap"
import Navbar from "./navBar"
import Footer from "./footer"
import ScrollReveal from 'scrollreveal'

const Layout = ({ children, pageInfo }) => {
  React.useEffect(()=> {
    ScrollReveal().reveal('.layout-foo',
      {
        delay: 0, // 延迟
        distance: '50px',
        easing: 'ease-in-out',
        origin: "bottom",
        mobile: false,
        duration: 400,
        interval: 50,
        opacity : 0, 
        reset: false,
        init: false
      }
    )
  })
  return (
  <Container fluid className="px-0 main">
    <Navbar pageInfo={pageInfo} />
    <main>{children}</main>
    <Footer />
  </Container>
  )

}

export default Layout
